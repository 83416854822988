<template>
  <g id="g1096" clip-path="url(#clipPath1100)"
    ><path
      d="m 9213,5729.5 c 0,146.0791 -118.4209,264.5 -264.5,264.5 -146.0791,0 -264.5,-118.4209 -264.5,-264.5 0,-146.0791 118.4209,-264.5 264.5,-264.5 146.0791,0 264.5,118.4209 264.5,264.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1102"/><path
      d="m 2182,8301.5 c 0,146.0791 -118.4206,264.5 -264.5,264.5 -146.0793,0 -264.5,-118.4209 -264.5,-264.5 0,-146.0791 118.4207,-264.5 264.5,-264.5 146.0794,0 264.5,118.4209 264.5,264.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1104"/><path
      d="m 8873,7015.5 c 0,-41.6973 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8027 75.5,75.5 0,41.6973 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8027 -75.5,-75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1106"/><path
      d="m 1842,7015.5 c 0,-41.6973 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8027 75.5,75.5 0,41.6973 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8027 -75.5,-75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1108"/><path
      d="m 8892,6637.5 c 0,-31.2041 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.2959 56.5,56.5 0,31.2041 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.2959 -56.5,-56.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1110"/><path
      d="m 9062,8301.5 c 0,62.6846 -50.8154,113.5 -113.5,113.5 -62.6846,0 -113.5,-50.8154 -113.5,-113.5 0,-62.6846 50.8154,-113.5 113.5,-113.5 62.6846,0 113.5,50.8154 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1112"/><path
      d="m 8788,8301.5 c 0,-88.6416 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.8584 160.5,160.5 0,88.6416 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.8584 -160.5,-160.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1114"/><path
      d="m 2031,5729.5 c 0,62.6841 -50.8157,113.5 -113.5,113.5 -62.6843,0 -113.5,-50.8159 -113.5,-113.5 0,-62.6841 50.8157,-113.5 113.5,-113.5 62.6843,0 113.5,50.8159 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1116"/><path
      d="m 1757,5729.5 c 0,-88.6416 71.8583,-160.5 160.5,-160.5 88.6417,0 160.5,71.8584 160.5,160.5 0,88.6416 -71.8583,160.5 -160.5,160.5 -88.6417,0 -160.5,-71.8584 -160.5,-160.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1118"/><path
      d="m 3769,8527.5 c 0,20.7109 -16.7893,37.5 -37.5,37.5 -20.7107,0 -37.5,-16.7891 -37.5,-37.5 0,-20.7109 16.7893,-37.5 37.5,-37.5 20.7107,0 37.5,16.7891 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1120"/><path
      d="m 7172,8527.5 c 0,20.7109 -16.7896,37.5 -37.5,37.5 -20.7105,0 -37.5,-16.7891 -37.5,-37.5 0,-20.7109 16.7895,-37.5 37.5,-37.5 20.7104,0 37.5,16.7891 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1122"/><path
      d="m 8306,5276.5 c 0,20.7104 -16.7891,37.5 -37.5,37.5 -20.7109,0 -37.5,-16.7896 -37.5,-37.5 0,-20.7105 16.7891,-37.5 37.5,-37.5 20.7109,0 37.5,16.7895 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1124"/><path
      d="m 2635,7015.5 c 0,20.7104 -16.7893,37.5 -37.5,37.5 -20.7107,0 -37.5,-16.7896 -37.5,-37.5 0,-20.7105 16.7893,-37.5 37.5,-37.5 20.7107,0 37.5,16.7895 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1126"/><path
      d="m 8306,8754.5 c 0,20.7109 -16.7891,37.5 -37.5,37.5 -20.7109,0 -37.5,-16.7891 -37.5,-37.5 0,-20.7109 16.7891,-37.5 37.5,-37.5 20.7109,0 37.5,16.7891 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1128"/><g id="g1130" transform="rotate(-90,6829,-1609.5)"
      ><path
        d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1132"/></g
    ><path
      d="M 8551,5219 V 8811"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1134"/><path
      d="m 8552,8811.5 c 0,62.6846 -50.8154,113.5 -113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1136"/><path
      d="M 8438,8924 H 2427"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1138"/><g id="g1140" transform="rotate(90,-3192,5619.5)"
      ><path
        d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1142"/></g
    ><path
      d="M 2314,8811 V 5219"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1144"/><g id="g1146" transform="rotate(180,1213.75,2609.75)"
      ><path
        d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1148"/></g
    ><path
      d="M 2427,5106 H 8438"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1150"/><path
      d="m 9327,8905.5 c 0,62.6846 -50.8154,113.5 -113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1152"/><path
      d="M 9213,9019 H 1652"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1154"/><g id="g1156" transform="rotate(90,-3626.5,5279)"
      ><path
        d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1158"/></g
    ><path
      d="M 1539,8905 V 5125"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1160"/><g id="g1162" transform="rotate(180,826.25,2562.75)"
      ><path
        d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1164"/></g
    ><path
      d="M 1652,5011 H 9213"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1166"/><g id="g1168" transform="rotate(-90,7169.5,-2044)"
      ><path
        d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1170"/></g
    ><path
      d="M 9326,5125 V 8905"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1172"
  /></g>
</template>
<script>
export default {
  name: "Ma260Bottom",
};
</script>